<template>
    <div class="">
        <div class="card">
            <div class="card-header">
            <h6 class="card-title mb-0">Business attachments</h6>
            </div>
            <div class="card-body">
            <div class="row g-4">
                <div v-for="(file, fIndex) in items" :key="fIndex"
                class="col-12" >
                <div>
                    <div @click="showImg(fIndex)" class="cursor-pointer">
                        <img :src="absoluteUrl(file.path)" class="mb-2 w-100" />
                    </div>
                    <span>{{file.name}}</span>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    
    export default {
        props:{
            items:{
                default:()=>([])
            }
        },
        computed:{
            lightboxFiles(){
                return this.items.map(file => {
                    if(file.is_video){
                        return {
                            title: file.name,
                            thumb: this.absoluteUrl(file.path),
                            mediaType: 'video',
                            src: this.absoluteUrl(file.video_path) }
                    }else{
                        return {
                            title: file.name,
                            mediaType: 'image',
                            src: this.absoluteUrl(file.path) }
                        }
                })
            }
        },
        methods:{
            showImg(index) {
                let item = { index: index, visible: true, files: this.lightboxFiles};
                this.$emit('showImages', item)
            },
        },
    }
    </script>
    
    <style>
    
    </style>